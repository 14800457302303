import React from "react";
import { Container } from "semantic-ui-react";

import LayoutInner from "../components/layout/layout-inner";
import SEO from "../components/seo/seo";
import BodyText from "../components/body-text";
import SecondaryNavBar from "../components/secondary-nav-bar/secondary-nav-bar";

const Page = () => (
  <LayoutInner>
    <SEO title="Recruitment of Ex Offenders Policy" keywords={[`rocksteady`]} />
    <SecondaryNavBar
      product="generic"
      title="Policy"
      titlePath="/recruitment-of-ex-offenders/"
      titleActive={true}
      links={[]}
    />

    <Container>
      <BodyText>
        <h1>
          <strong>Policy on the Recruitment of Ex-offenders</strong>
        </h1>
        <ul>
          <li>
            <blockquote>
              <p>
                As an organisation using the Disclosure &amp;
                Barring&nbsp;Service to assess applicants’ suitability for
                positions of trust, we aim to comply fully with the{" "}
                <a href="https://www.gov.uk/government/uploads/system/uploads/attachment_data/file/474742/Code_of_Practice_for_Disclosure_and_Barring_Service_Nov_15.pdf">
                  code of practice
                </a>{" "}
                and undertake to treat all applicants for positions fairly.
              </p>
            </blockquote>
          </li>
          <li>
            <blockquote>
              <p>
                A DBS check is currently proportionate and relevant to all roles
                at Rocksteady. Our recruitment information contains a statement
                that a DBS check&nbsp;will be requested in the event of the
                individual being offered the position.
              </p>
            </blockquote>
          </li>
          <li>
            <blockquote>
              <p>
                We are committed to the fair treatment of staff, potential
                staff, and of our services, regardless of race, gender,
                religion, sexual orientation, responsibilities for dependants,
                age, physical/mental disability, or offending background
              </p>
            </blockquote>
          </li>
          <li>
            <blockquote>
              <p>
                This policy is made available to all DBS applicants at the start
                of the recruitment process
              </p>
            </blockquote>
          </li>
          <li>
            <blockquote>
              <p>
                We actively promote equality of opportunity for all with the
                right mix of talent, skills, and potential and welcome
                applications from a wide range of candidates. We select all
                candidates for interview based on their aptitude, skills,
                qualifications, and experience in relation to the requirements
                of the post.
              </p>
            </blockquote>
          </li>
          <li>
            <blockquote>
              <p>
                We encourage all applicants called for interview to provide
                details of any criminal record at an early stage in the
                application process. We guarantee that this information is only
                be seen by those who need to see it as part of the recruitment
                process.
              </p>
            </blockquote>
          </li>
          <li>
            <blockquote>
              <p>
                We ensure that all those who are involved in the recruitment
                process will have suitable guidance to enable them to identify
                and assess the relevance of the offence in relation to the
                position applied for. We also ensure that they receive
                appropriate guidance on the relevant legislation relating to the
                employment of ex-offenders, e.g. the Rehabilitation of Offenders
                Act 1974.
              </p>
            </blockquote>
          </li>
          <li>
            <blockquote>
              <p>
                At interview, or in a separate discussion, we ensure that an
                open and measured discussion takes place on the subject of any
                offences or other matter that might be relevant to the position.
                Failure to reveal information that is directly relevant to the
                position sought could lead to withdrawal of an offer of
                employment
              </p>
            </blockquote>
          </li>
          <li>
            <blockquote>
              <p>
                We make every subject of a criminal record check submitted to
                DBS aware of the existence of the&nbsp;
                <a href="https://www.gov.uk/government/publications/dbs-code-of-practice">
                  code of practice
                </a>
                &nbsp;and makes a copy available on request
              </p>
            </blockquote>
          </li>
          <li>
            <blockquote>
              <p>
                We undertake to discuss any matter revealed on a DBS certificate
                with the individual seeking the position before withdrawing a
                conditional offer of employment
              </p>
            </blockquote>
          </li>
        </ul>
      </BodyText>
    </Container>
  </LayoutInner>
);

export default Page;
